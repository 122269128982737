const utils = {
  timeout(func, ms) {
    return new Promise(resolve => setTimeout(() => { func(); resolve(); }, ms));
  },
  withGrid(n) {
    return n * 16;
  },
  asGridCoord(object) {
    let result = {};
    Object.entries(object).forEach(([coord, value], _) => {
      let [x, y] = coord.split(",");
      result[`${x * 16},${y * 16}`] = value;
    })
    return result;
  },
  nextPosition(initialX, initialY, direction) {
    let x = initialX;
    let y = initialY;
    const size = 16;
    if (direction === "left") {
      x -= size;
    } else if (direction === "right") {
      x += size;
    } else if (direction === "up") {
      y -= size;
    } else if (direction === "down") {
      y += size;
    }
    return { x, y };
  },
  oppositeDirection(direction) {
    if (direction === "left") { return "right" }
    if (direction === "right") { return "left" }
    if (direction === "up") { return "down" }
    return "up"
  },

  emitEvent(name, detail) {
    const event = new CustomEvent(name, {
      detail
    });
    document.dispatchEvent(event);
  }

}

export default utils;