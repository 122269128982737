import Person from "./Person";
import utils from "./utils";
import _ from "lodash";

// maps
import DemoLower from "./images/maps/DemoLower.png";
import DemoUpper from "./images/maps/DemoUpper.png";
import KitchenLower from "./images/maps/KitchenLower.png";
import KitchenUpper from "./images/maps/KitchenUpper.png";

//characters
import NPC1 from "./images/characters/people/npc1.png";
import NPC2 from "./images/characters/people/npc2.png";
import NPC3 from "./images/characters/people/npc3.png";
import NPC4 from "./images/characters/people/npc4.png";

const initialConfigs = {
    DemoRoom: {
        mapName: "DemoRoom",
        lowerSrc: DemoLower,
        upperSrc: DemoUpper,
        gameObjects: {
            hero: new Person({
                // x: utils.withGrid(utils.randomIntFromInterval(2, 6)),
                // y: utils.withGrid(utils.randomIntFromInterval(5, 6)),
                x: utils.withGrid(5),
                y: utils.withGrid(5),
                isPlayerControlled: true
            }),
            npcA: new Person({
                x: utils.withGrid(3),
                y: utils.withGrid(9),
                src: NPC1,
                behaviorLoop: [
                    { type: "walk", direction: "left" },
                    { type: "stand", direction: "left", time: 2000 },
                    { type: "walk", direction: "right" },
                    { type: "stand", direction: "right", time: 2000 },
                ],
                talking: [
                    {
                        events: [
                            { type: "textMessage", text: "Đang bận vcl...", faceHero: "npcA" },
                            { type: "textMessage", text: "Né hộ!!!" },
                            { who: "hero", type: "walk", direction: "up" }
                        ]
                    }
                ]
            }),
            npcB: new Person({
                x: utils.withGrid(8),
                y: utils.withGrid(5),
                src: NPC2,
                // behaviorLoop: [
                //     { type: "walk", direction: "left" },
                //     { type: "stand", direction: "up", time: 800 },
                //     { type: "walk", direction: "up" },
                //     { type: "walk", direction: "right" },
                //     { type: "walk", direction: "down" },
                // ]
            })
        },
        walls: utils.asGridCoord({
            [[7, 6]]: true,
            [[8, 6]]: true,
            [[7, 7]]: true,
            [[8, 7]]: true,
            [[1, 3]]: true,
            [[2, 3]]: true,
            [[3, 4]]: true,
            [[4, 4]]: true,
            [[5, 3]]: true,
            [[9, 3]]: true,
            [[10, 3]]: true,
            [[8, 4]]: true,
            [[7, 2]]: true,
            [[6, 3]]: true,
            [[8, 3]]: true,
            [[6, 4]]: true,
            [[11, 4]]: true,
            [[11, 5]]: true,
            [[11, 6]]: true,
            [[11, 7]]: true,
            [[11, 8]]: true,
            [[11, 9]]: true,
            [[0, 4]]: true,
            [[0, 5]]: true,
            [[0, 6]]: true,
            [[0, 7]]: true,
            [[0, 8]]: true,
            [[0, 9]]: true,
            [[1, 10]]: true,
            [[2, 10]]: true,
            [[3, 10]]: true,
            [[4, 10]]: true,
            [[5, 11]]: true,
            [[6, 10]]: true,
            [[7, 10]]: true,
            [[8, 10]]: true,
            [[9, 10]]: true,
            [[10, 10]]: true,
        }),
        cutsceneSpaces: utils.asGridCoord({
            [[7, 4]]: [
                {
                    events: [
                        { who: "npcB", type: "walk", direction: "left" },
                        { who: "npcB", type: "stand", direction: "up", time: 500 },
                        { type: "textMessage", text: "Cút" },
                        { who: "npcB", type: "walk", direction: "right" },
                        { who: "npcB", type: "stand", direction: "down" },

                        { who: "hero", type: "walk", direction: "down" },
                        { who: "hero", type: "walk", direction: "left" }
                    ]
                }
            ],
            [[5, 10]]: [
                {
                    events: [
                        { type: "changeMap", map: "Kitchen" } // open cut scene
                    ]
                }
            ]
        }),
    },
    Kitchen: {
        mapName: "Kitchen",
        lowerSrc: KitchenLower,
        upperSrc: KitchenUpper,
        gameObjects: {
            hero: new Person({
                x: utils.withGrid(5),
                y: utils.withGrid(10),
                isPlayerControlled: true,
            }),
            npcA: new Person({
                x: utils.withGrid(3),
                y: utils.withGrid(5),
                src: NPC3,
                talking: [
                    {
                        events: [
                            { type: "textMessage", text: "Chào mừng đến với Nhà bếp!", faceHero: "npcA" }
                        ]
                    }
                ]
            }),
            npcB: new Person({
                x: utils.withGrid(10),
                y: utils.withGrid(6),
                src: NPC4
            })
        },
        walls: utils.asGridCoord({
            [[1, 4]]: true,
            [[2, 4]]: true,
            [[3, 4]]: true,
            [[4, 3]]: true,
            [[5, 4]]: true,
            [[6, 4]]: true,
            [[7, 4]]: true,
            [[8, 4]]: true,
            [[9, 4]]: true,
            [[10, 4]]: true,
            [[11, 5]]: true,
            [[12, 5]]: true,
            [[1, 9]]: true,
            [[2, 9]]: true,
            [[3, 10]]: true,
            [[4, 10]]: true,
            [[5, 11]]: true,
            [[6, 10]]: true,
            [[7, 10]]: true,
            [[8, 10]]: true,
            [[9, 9]]: true,
            [[10, 9]]: true,
            [[11, 10]]: true,
            [[12, 10]]: true,
            [[1, 5]]: true,
            [[1, 6]]: true,
            [[1, 7]]: true,
            [[0, 8]]: true,
            [[13, 6]]: true,
            [[13, 7]]: true,
            [[13, 8]]: true,
            [[13, 9]]: true,
            [[6, 7]]: true,
            [[7, 7]]: true,
            [[9, 7]]: true,
            [[10, 7]]: true

        }),
        cutsceneSpaces: utils.asGridCoord({
            [[5, 10]]: [
                {
                    events: [
                        { type: "changeMap", map: "DemoRoom" }
                    ]
                }
            ]
        }),
        startMapCutscene: utils.asGridCoord({
            [[5, 10]]: [
                {
                    events: [
                        { who: "hero", type: "walk", direction: "up" }
                    ]
                }
            ]
        })
    }
}
class MapsConfigs {
    constructor() {
        this.configs = _.cloneDeep(initialConfigs);
    }


    userLeave(map) {
        console.log(this.configs[map]);
        console.log('init', initialConfigs);
        this.configs[map] = {
            ...initialConfigs[map],
            gameObjects: {
                ...initialConfigs[map]["gameObjects"],
                hero: this.configs[map]["gameObjects"]["hero"]
            }
        };
        console.log('left');
    }

    addPlayers(players) {
        Object.entries(players).forEach(([key, value], _) => {
            this.configs[value.mapName]["gameObjects"][key] = new Person(value);
            console.log(this.configs, 'after join')
        });
    }

    newPlayer(player) {
        console.log(`new ${player} joined`)
        return this.configs[player.mapName]["gameObjects"][player.userName] = new Person(player);
    }

    removePlayer(player, map) {
        delete this.configs[player.mapName]["gameObjects"][player.userName]
    }

    init() {
        window.OverworldMaps = this.configs;
    }
}

export default MapsConfigs;
