import OverworldMap from "./OverworldMap";
import KeyPressListener from "./KeyPressListener";
import DirectionInput from "./DirectionInput";
import Multiplayer from "./Multiplayer";
import MapsConfigs from "./MapConfigs";

class Overworld {
  constructor(config) {
    this.userName = config.userName;
    this.element = config.element;
    this.canvas = this.element.querySelector(".game-canvas");
    this.ctx = this.canvas.getContext("2d");
    this.map = null;
    this.multiplayerSocket = null;
    this.mapConfigs = null;
    this.isFocus = true;
  }

  startGameLoop() {
    const step = () => {
      //Clear off the canvas
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      //Establish the camera person
      const cameraPerson = this.map.gameObjects.hero;

      //Update all objects
      Object.values(this.map.gameObjects).forEach(object => {
        object.update({
          arrow: this.directionInput.direction,
          map: this.map,
        })
      })

      //Draw Lower layer
      this.map.drawLowerImage(this.ctx, cameraPerson);

      //Draw Game Objects
      Object.values(this.map.gameObjects).sort((a, b) => {
        return a.y - b.y;
      }).forEach(object => {
        object.sprite.draw(this.ctx, cameraPerson);
      })

      //Draw Upper layer
      this.map.drawUpperImage(this.ctx, cameraPerson);

      requestAnimationFrame(() => {
        step();
      })
    }
    step();
  }

  bindActionInput() {
    new KeyPressListener("Space", () => {
      //Is there a person here to talk to?
      this.map.checkForActionCutscene()
    })
  }

  bindHeroPositionCheck() {
    document.addEventListener("PersonWalkingComplete", e => {
      if (e.detail.whoId === "hero") {
        // Hero's position has changed
        // Check for cut scence
        this.map.checkForFootstepCutscene(this.multiplayerSocket)

        // Update hero position to server
        this.multiplayerSocket.sendPlayerData({
          userName: this.userName,
          direction: e.detail.direction,
          x: e.detail.x,
          y: e.detail.y
        })
      }
    })
  }

  async startSocket() {
    this.multiplayerSocket = new Multiplayer({ map: this.map, userName: this.userName, mapConfigs: this.mapConfigs });
    await this.multiplayerSocket.init();
  }

  async startMap(mapConfig, openCutscene = undefined, initial = false) {
    this.map = new OverworldMap(mapConfig);
    this.map.overworld = this;

    await this.startSocket();

    await this.multiplayerSocket.joinRoom(this.map.mapName, this.map.gameObjects.hero);
    this.map.mountObjects();
    if (openCutscene) {
      this.map.startCutscene(openCutscene);
    }
  }

  async init() {
    this.mapConfigs = new MapsConfigs();
    this.mapConfigs.init();

    await this.startMap(window.OverworldMaps.DemoRoom, undefined, true);

    window.onfocus = () => {
      this.isFocus = true;
    }
    window.onblur = () => {
      this.isFocus = false;
    }

    this.bindActionInput();
    this.bindHeroPositionCheck();

    this.directionInput = new DirectionInput();
    this.directionInput.init();

    this.startGameLoop();


    // this.map.startCutscene([
    //   { who: "hero", type: "walk",  direction: "down" },
    //   { who: "hero", type: "walk",  direction: "down" },
    //   { who: "npcA", type: "walk",  direction: "up" },
    //   { who: "npcA", type: "walk",  direction: "left" },
    //   { who: "hero", type: "stand",  direction: "right", time: 200 },
    //   { type: "textMessage", text: "WHY HELLO THERE!"}
    //   // { who: "npcA", type: "walk",  direction: "left" },
    //   // { who: "npcA", type: "walk",  direction: "left" },
    //   // { who: "npcA", type: "stand",  direction: "up", time: 800 },
    // ])

  }
}

export default Overworld;
