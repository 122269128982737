import Overworld from "./Overworld";


function createCanvas(gameContainer) {
  const gameCanvas = document.createElement("canvas");
  gameCanvas.classList.add('game-canvas');
  gameCanvas.width = 352;
  gameCanvas.height = 198;

  gameContainer.append(gameCanvas);
}

function getUserName(gameContainer) {
  const userNameInputDiv = document.createElement("div");
  userNameInputDiv.classList.add('userNameInput');
  userNameInputDiv.innerHTML = `
    <label for='userNameInput_input'>User name:</label>
    <input type="text" id="userNameInput_input" class="userNameInput_input">
    <button class="userNameInput_button" type="button">OK</button>
  `

  gameContainer.append(userNameInputDiv);

  userNameInputDiv.querySelector('.userNameInput_button').addEventListener("click", () => {
    let value = userNameInputDiv.querySelector('.userNameInput_input').value;
    if (value !== "") {
      userNameInputDiv.remove();
      createCanvas(gameContainer);

      const overworld = new Overworld({
        element: gameContainer,
        userName: value
      });

      overworld.init();
    }
  })
}

(function () {
  const gameContainer = document.querySelector(".game-container");

  getUserName(gameContainer);
})();
