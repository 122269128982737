import { io } from 'socket.io-client';
import utils from './utils';


class Multiplayer {
    constructor(config) {
        this.userName = config.userName;
        this.mapConfigs = config.mapConfigs;
        this.map = config.map;
        this.socket = null;
        this.players = {};

        this.timeout = null;
        this.recallLimit = 5;
        this.recallCount = 0;
    }

    parseUserInfo(mapName, userInfo) {
        return {
            userName: this.userName,
            x: userInfo.x,
            y: userInfo.y,
            direction: userInfo.direction,
            mapName,
            type: "otherPlayer"
        }
    }

    disconnectSocket(mapName) {
        if (this.socket) {
            this.socket.disconnect();
            this.mapConfigs.userLeave(mapName);
        }
    }

    joinRoom(mapName, userInfo) {
        // subscribe to socket room according to current map
        // receive players list in that map
        return new Promise(resolve => {
            this.socket.emit('join room', {
                userInfo: this.parseUserInfo(mapName, userInfo)
            });
            console.log('call join room')
            this.socket.on('joined', (code, players) => {
                if (code === 200) {
                    this.socket.off('joined');
                    this.players = players;
                    resolve(players);
                    this.mapConfigs.addPlayers(players);
                    console.log(players, 'yes')
                }
            })
        })
    }

    // leaveRoom(mapName) {
    //     this.socket.emit('leave room', {
    //         userName: this.userName
    //     });
    //     this.mapConfigs.userLeave(mapName);
    // }

    sendPlayerData(userData) {
        this.socket.emit('update character', userData);
    }

    listenToUserUpdate() {
        this.socket.on('user update', userData => {
            console.log(`user ${userData["userName"]} moved to ${userData["direction"]}`)
            this.players[userData["userName"]] = { ...this.players[userData["userName"]], ...userData }
            console.log(this.map.overworld.isFocus)
            if (this.map.overworld.isFocus === true) {
                this.map.gameObjects[userData["userName"]].update({
                    arrow: userData["direction"],
                    map: this.map
                }, true);
            } else {
                console.log(this.map.gameObjects);
                this.map.gameObjects[userData["userName"]].x = userData["x"];
                this.map.gameObjects[userData["userName"]].y = userData["y"];
            }

        });
    }

    listenToNewUser() {
        this.socket.on('new user joined', userData => {
            this.players[userData["userName"]] = userData;
            console.log('new user', userData);
            const newPlayerObj = this.mapConfigs.newPlayer(userData);
        });
    }

    listenUserLeaving() {
        this.socket.on('user left', userInfo => {
            console.log(`user ${userInfo.userName} left`)
            delete this.players[userInfo.userName]
            console.log(userInfo, 'nooo');
            this.mapConfigs.removePlayer(userInfo, this.map);
        })
    }

    turnOffListeners() {
        this.socket.off();
    }

    listenToConnection(resolve) {
        this.socket.on("connect", () => {
            if (this.socket.connected) {
                console.log('server connected');
                this.listenToUserUpdate();
                this.listenToNewUser();
                this.listenUserLeaving();
                resolve();
            }

            if (this.socket.disconnected) {
                console.log('server disconnected');
                this.turnOffListeners();
            }
        });
    }

    init() {
        return new Promise(resolve => {
            console.log('connecting');
            this.socket = io(process.env.NODE_ENV_SOCKET_ENDPOINT);
            console.log(process.env.NODE_ENV_SOCKET_ENDPOINT);
            this.listenToConnection(resolve);
        })
    }
}


export default Multiplayer;
